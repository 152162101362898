<template>
  <SinglePage>
    <div class="grid-x align-center">
      <div class="cell small-12 medium-7">
        <h1>🚀 Då var det klart!</h1>
        <p>Du kan nu anmäla intresse till bostäder på BytBotad! Här får du några tips på vad du kan göra för att öka dina chanser att få till ett byte!</p>
<h2>📸 Bra bilder</h2>
<p>Du vet själv hur det är! När det handlar om att byta bostad så vill du se vad som finns. Ta många och bra bilder till din annons. Se till att fotografera det som du själv tycker är vitkigt när du söker bostad. Kök, badrum, förvaring mm.</p>

<h2>📚 Skriv en bra beskrivning</h2>
<p>Du behöver inte skriva en hel bok. Men beskriv din bostad så bra du bara kan. När byttes köket ut? Är det stambyte på gång? Finns det bostadsrättsförening bildad? Är det ett lugnt område? Tänk på alla frågor du själv har. Ju mer desto bättre.</p>
<h2>👩‍💻 Var aktiv</h2>
<p>Ha som rutin att logga in ett par gånger i veckan för att markera intresse.</p>
<h2>⏳ Ha tålamod</h2>
<p>Att byta bostad tar tid. Alla loggar inte in varje dag, så du år ha tålamod medans vi presenterar din annons för andra medlemmar. Medans du sover så jobbar vårt system för fullt för att pussla ihop byten och presentera din annons för andra medlemmar.</p>
<h2>📢 Berätta om BytBostad</h2>
<p>Ju fler som känner till BytBostad, desto större är chansen att du hittar något. Tipsa dina kollegor och vänner. Dela din annons på Facebook eller Twitter.</p>

<h2>💬 Hör av dig om det är något du undrar</h2>
<p>Om det är något du undrar över, eller behöver hjälp med, så tveka inte att skicka iväg ett mail till info@bytbostad.se så hjälper vi dig så fort vi kan. Och har du förslag på hur vi kan bli bättre så blir vi grymt glada om du vill dela med dig av dom!</p>
  

  <h2>🏁 Är du redo?</h2>
  <p>Då är det bara att sätta igång!</p>
 <router-link tag="button"
                             class="button"
                             :to="{ name: 'adlist' }"
                             style="align-self:center;justify-self:right;text-decoration:none;">
                  Hitta ett byte
                </router-link>
  
      </div>
    </div>
  </SinglePage>
</template>

<script>
import SinglePage from '@/components/SinglePage.vue'
export default {
  components: {
    SinglePage
  }
}
</script>

